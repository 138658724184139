import React, { useEffect } from "react"
import Meta from "../utilities/seo"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Box = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Ele = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "xeno-2023-financials" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  useEffect(() => {
    data.allFile.edges.forEach(file => {
      if (file.node.name == "xeno-2023-financials") {
        let url = "https://myxeno.com" + file.node.publicURL
        window.location.href = url
      }
    })
  }, [])
  return (
    <>
      <Meta title="XENO 2023 Financials" />
      <Box>
        <h4>Loading please wait.....</h4>
      </Box>
    </>
  )
}

export default Ele
